<script>
  import Layout from "../../layouts/main";
  import EasyDataTable from "vue3-easy-data-table";
  import "vue3-easy-data-table/dist/style.css";
  import axios from "axios";
  // import * as pdfjsLib from 'pdfjs-dist';
  import base_domain from "../../myvars";
  axios.defaults.baseURL = base_domain;
  import jsPDF from "jspdf";

  export default {
    components: {
      Layout,
      EasyDataTable,
    },
    data() {
      return {
        utype: localStorage.getItem("userType"),
        // id: this.app_id, // Replace with the actual ID value
        // token: toooken, // Replace with the actual token value
        app_id: "",
        review_user: "",
        itemsss: [0],
        askreview: false,
        showModal: false,
        searchValueTable: "",
        selectStatusPro: "select",
        selectFilterSort: null,
        headers: [
          { text: "Sr. No.", value: "sr" },
          { text: "Application No.", value: "number" },
          { text: "Applicant Name", value: "applicantName" },
          // { text: "Level", value: "level" },
          { text: "Assign Staff", value: "assign" },
          { text: "Action", value: "view" },
        ],
        items: [],
        arrItem: [],
      };
    },

    beforeMount() {
      this.showdata();
      this.viewApplication();
    },

    methods: {
      // downloadPDF() {
      //   try {
      //     // Create a new jsPDF instance
      //     const pdf = new jsPDF();

      //     // Add content to the PDF document
      //     pdf.text("Your data goes here", 10, 10);

      //     // Save the PDF
      //     pdf.save("output.pdf");
      //   } catch (error) {
      //     console.error("Error converting to PDF:", error);
      //   }
      // },
      downloadPDF(app_id) {
        try {
          const t = localStorage.getItem("accessToken");
          axios.post("applications/toggle_it/", {
            token: t,
            app_id: app_id,
          })
            .then((response) => {
              // Extract data from the response
              const data = response.data;

              // Create a new jsPDF instance
              const pdf = new jsPDF();

              // Add content to the PDF document using the fetched data
              pdf.text(`Name: ${data.name}`, 10, 10);
              pdf.text(`Email: ${data.email}`, 10, 20);
              // Add more content as needed

              // Save the PDF
              pdf.save("output.pdf");
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } catch (error) {
          console.error("Error converting to PDF:", error);
        }
      },

      duplicateItem() {
        this.itemsss.push(Date.now());
      },
      filterStatus() {
        if (this.selectFilterSort == null) {
          this.arrItem = this.items;
        } else {
          this.arrItem = this.items.filter((item) => {
            return item.level == this.selectFilterSort;
          });
        }
      },
      showdata() {
        let arrList = [];
        const accessToken = localStorage.getItem("accessToken");
        console.log("yshhhhhhhhhh h token", accessToken);
        axios
          .get("/applications/all_application_details/", {
            params: {
              access_token: accessToken,
            },
          })
          .then((response) => {
            const user_data = response.data;
            this.review_user = localStorage.getItem("accessToken");
            const check_type = localStorage.getItem("userType");
            console.log("this is for yes check ---", user_data);

            if (check_type === "staff" || check_type === "verification officer") {
              let responseList = response.data["all_details"];
              responseList.forEach((item, index) => {
                let tok = false;
                if (item.app_status === "yes") {
                  tok = item.user !== this.review_user;
                }
                let newItem = {
                  sr: index + 1,
                  app_id: item.id,
                  number: item.application_no,
                  applicantName: `${(item.section_a1 && item.section_a1.first_name) || "N/A"
                    } ${(item.section_a1 && item.section_a1.sur_name) || ""}`,
                  // assign: item.curr_reviewer,
                  assign: item.rev,
                  user_tok: tok,

                  // level: item.risk_cat,
                  // statusLog:item.status,
                  // actionBtnUrl:"#",
                };
                // console.log("this is id test ", newItem);
                // Push the transformed item to the existing array
                arrList.push(newItem);
              });
              // console.log("NEW ARRAY DATa",arrList);
              this.arrItem = arrList;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },

      updateappstatus(verified, aid) {
        //toaccept or reject application for verification
        const accessToken = localStorage.getItem("accessToken");
        let action = verified == true ? "verify" : "rejected";
        axios
          .post("/applications/updateappstatus/", {
            access_token: accessToken,
            action: action,
            aid: aid,
          })
          .then((response) => {
            if (response.data.status == "SUCCESS") {
              console.log("status updated___", action);
              this.showdata();
            } else {
              console.log("failed to update status___");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },

      viewApplication(app_id) {
        const t = localStorage.getItem("accessToken");
        console.log("this token is valid", t, app_id);
        axios
          .post("applications/view_application/", {
            headers: {
              token: `Bearer ${t}`,
              app_id: this.app_id,
            },
          })
          .then((response) => {
            // Handle the API response
            console.log(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
  };
</script>

<template>
  <Layout>
    <br /><br /><br />
    <h3>All Applications</h3>
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center mb-3 justify-content-end">
          <!-- <div class="col-md-3 mb-md-0 mb-3">
            <label class="font-size-14 mb-1 me-2">Risk Category</label>
            <select
              class="form-select form-control simpleSelectStyle"
              v-model="selectFilterSort"
              @change="filterStatus"
            >
              <option selected :value="null">All</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div> -->
          <br />
          <div class="col-md-3">
            <div class="groupSearchTop">
              <label class="font-size-14 mb-1 me-2">Search</label>
              <input type="text" v-model="searchValueTable" class="simpleInputStyle form-control" />
            </div>
          </div>
        </div>
        <EasyDataTable :headers="headers" :items="arrItem" :search-value="searchValueTable" border-cell
          theme-color="#df6a0d" :rows-per-page="10" buttons-pagination table-class-name="table-custom-style">
          <template #item-view="{ app_id, user_tok }">
            <div class="d-flex button-row">
              <input type="hidden" />
              <b-button :to="`/view_application/${app_id}`" class="btn-sm btn-theme-dark" title="View Application">
                View Application
              </b-button>

              <div v-if="utype == 'staff'">
                <b-button :to="`/review_application/${app_id}`" class="btn-sm btn-theme-dark" :disabled="user_tok">
                  Review Application
                </b-button>
              </div>
              <div v-if="utype == 'verification officer'">
                <b-button class="btn-sm btn-success" @click="updateappstatus(true, app_id)">Verify</b-button>
                <b-button class="btn-sm btn-danger" @click="updateappstatus(false, app_id)">Reject</b-button>
              </div>

              <!-- <b-button
                @click="downloadPDF(app_id)"
                class="btn-sm btn-theme-dark"
              >
                Download PDF
              </b-button> -->

              <!-- <b-button class="btn-sm btn-theme" @click="showModal = true"
                >Feedback</b-button
              > -->
            </div>
          </template>
        </EasyDataTable>

        <b-modal title="Document Feedback" v-model="showModal" hide-footer centered>
          <div class="d-block mb-3">
            <label class="font-size-14">Application Status</label>
            <div class="single-select2-cstm">
              <select v-model="selectStatusPro" class="form-select form-control selectCustomSize">
                <option selected disabled value="select">Please Select</option>
                <option value="inProgress">In progress</option>
                <option value="submitReview">Submitted and Under Review</option>
                <option value="incomplete">Incomplete</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="processed">Processed</option>
              </select>
            </div>
          </div>
          <template v-if="
              selectStatusPro === 'incomplete' || selectStatusPro === 'rejected'
            ">
            <div class="groupFormStyle duplicate item mb-3" v-for="item in itemsss" :key="item">
              <template v-if="selectStatusPro !== 'rejected'">
                <div class="d-block mb-3">
                  <label class="font-size-14">Application Section</label>
                  <div class="single-select2-cstm">
                    <select class="form-select form-control selectCustomSize">
                      <option>Personal Information</option>
                      <option>Personal Information</option>
                      <option>Employment Information</option>
                      <option>Account Activity</option>
                      <option>Minors Only</option>
                      <option>Politically Exposed Persons</option>
                      <option>Foreign Account Tax Compliance ACT</option>
                      <option>Account/Fees</option>
                      <option>Appointment of Nominee</option>
                      <option>Members Declaration</option>
                    </select>
                  </div>
                </div>
              </template>
              <template v-if="
                  selectStatusPro == 'rejected' ||
                  selectStatusPro == 'incomplete'
                ">
                <div class="d-block mb-3">
                  <label class="font-size-14">Comment</label><textarea type="textarea" class="form-control" rows="3"
                    placeholder="Comment..."></textarea>
                </div>
              </template>
              <template v-if="selectStatusPro !== 'rejected'">
                <div class="d-block mb-3">
                  <label class="font-size-14">Bug Attached </label>
                  <input type="file" class="form-control" />
                </div>
              </template>
            </div>

            <div class="d-flex justify-content-center">
              <div class="item addMoreBug-btn" @click="duplicateItem">
                <button class="btn-sm btn-theme-dark">Add More Bug</button>
              </div>
            </div>
          </template>
          <div class="d-block text-end">
            <input type="submit" class="btn btn-theme btn-custom-wdth" value="Submit" />
          </div>
        </b-modal>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>